
$paging-item-size: 6px;
$paging-item-touch: 6px;
$paging-outer-distance: (20px - ($paging-item-size + $paging-item-touch));

.pageEl{
  .flexslider{
    &.slider{ // extra selector for specificity
      margin: 0;
    }
  }
  .slider.bottom-center-paging .slider-pagination,
  .slider.bottom-left-paging .slider-pagination,
  .slider.bottom-rigth-paging .slider-pagination{
    bottom: $paging-outer-distance;
  }
  .slider.top-center-paging .slider-pagination,
  .slider.top-left-paging .slider-pagination,
  .slider.top-rigth-paging .slider-pagination{
    top: $paging-outer-distance;
  }
  .slider-pagination .paging-item{
    // border: 2px solid #FFF;
    background: transparent;
    transition: background $transition-linear;
    &.flex-active, &:hover{
      // border: 2px solid $link-color;
      background: $red;
    }
  }
  .sn-media-slider .slider-pagination .paging-item {
    cursor: pointer;
    height: 10px;
    width: 10px;
    padding: $paging-item-size;
    border: $paging-item-touch solid transparent;
    background-clip: padding-box;
    &:after{
      content: "";
      display: block;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: 2px solid #FFF;
      border-radius: 100%;
      width: 17px;
      height: 17px;
    }
  }
  .sn-media-slider .slider-pagination{
    height: auto;
    line-height: 1;
  }
  // .paging-item + .paging-item{ margin-left: 14px; }

  // black overlay
  .media-wrapper a:last-child:before{
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    // background-color: rgba(0,0,0,.1);
    // background-image: linear-gradient(to top, rgba(#000, .75) 0%, rgba(#000, 0) 45%);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .mediaSlider{
    > h2, > p{display: none;} // hide elements while js is wrapping them
  }
  .sn-media-slider .slides .slide .slide-overlay,
  .sn-media-slider .slider-pagination{
    max-width: $maxWidth;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 20px;
    box-sizing: border-box;
  }
  .sn-media-slider .slider .slide-text{
    .slide-title, .slide-description{
      max-width: $maxWidth/2;
    }
  }
}


// .sn-media-slider .slides .slide .media-wrapper img {
//     display: block !important;
// }
.sn-media-slider .slider .slide-text{
  $media-slider-padding: 60px;
  &.top-left{padding-top: $media-slider-padding;}
  &.top-center{padding-top: $media-slider-padding;}
  &.top-right{padding-top: $media-slider-padding;}
  &.middle-left{padding-bottom: ($media-slider-padding / 2); padding-top: ($media-slider-padding / 2);}
  &.middle-center{padding-bottom: ($media-slider-padding / 2); padding-top: ($media-slider-padding / 2);}
  &.middle-right{padding-bottom: ($media-slider-padding / 2); padding-top: ($media-slider-padding / 2);}
  &.bottom-left{padding-bottom: $media-slider-padding;}
  &.bottom-left{padding-bottom: $media-slider-padding;}
  &.bottom-left{padding-bottom: $media-slider-padding;}
}
.sn-media-slider .slider .slide-title{
  font-family: $fontThree;
  color: $red;
  font-size: em(40);
  @media screen and(max-width:900px){font-size: em(36);}
  @media screen and(max-width:700px){font-size: em(32);}
  @media screen and(max-width:500px){font-size: em(30);}
  @media screen and(max-width:300px){font-size: em(28);}
  font-weight: 500;
  line-height: 1.2;
  text-transform: uppercase;
  margin: 0 0 0 0;
  background-color: rgba(#FFF, .9);
  padding: 15px;
  text-shadow: none;
  &:empty{display:none;}
}
.sn-media-slider .slider .slide-description{
  font-family: $fontThree;
  font-size: em(24);
  @media screen and(max-width:300px){font-size: em(24);}
  font-weight: 400;
  line-height: 1.2;
  text-transform: none;
  margin: 0;
  padding: 0 30px 30px 30px;
  text-shadow: none;
  background-color: rgba(#FFF, .3);
  &:empty{display:none;}
}
.edit_mode .sn-media-slider {
    margin-top: 0;
    margin-bottom: 0;
}


.media-wrapper{
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.sn-media-slider .slides .slide .media-wrapper img{
  display: none !important;
}

@media screen and (max-width: 700px){
  .layout-100{
    .sn-media-slider .flexslider{
      min-height: 300px;
    }
  }
}


.custom-media-slider{
  .sn-media-slider .flexslider{
    max-height: 400px;
  }
}
