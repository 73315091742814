// 5. Site Footer
.snFooterContainer{
  background-color: $gray-dk;
}
#siteFooter {
  width:100%;
  max-width:$maxWidth;
  margin:0 auto;

  display: block;
  margin: auto;
  padding: 40px 20px;
  position: relative;
  vertical-align: bottom;
  width: 100%;
  font-size: .8em;
  clear:both;
  > ul {
    text-align: center;
    padding: 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    li {
      display: inline-block;
      padding:0 .2em;
      min-height:20px;
      a { display: inline-block; }
      &:hover { display: inline-block; }
    }
  }
  #poweredByNGIN{
    margin-left: auto;
  	a{
  		display:inline-block;
  		width:120px;
  		height:20px;
  		position:relative;
  		&:after{
  			content:url($asset-path-for+'/logo_images/logo_black.svg');
  			display:block;
  			position:absolute;
  			top:4px;
  			left:0;
  			width:100%;
  		}
  		img{
  			display:none;
  		}
  	}
  }
}

//
// .link-column{
//   .textBlockElement{
//     .text{
//       font-family: $fontOne;
//       font-weight: 500;
//       font-size: em(16);
//       p{
//         margin-bottom: 0;
//       }
//       a{
//         color: $gray-dk;
//         border-bottom: 0;
//
//         &:hover{
//           color: $red;
//           border-bottom: 0;
//         }
//       }
//     }
//   }
// }
//
// .footer-links{
//   display: flex;
//   flex-flow: row wrap;
//   justify-content: space-around;
//   padding-bottom: 15px;
//   .pageEl{
//     flex: 0 1 auto;
//     padding: 0 15px;
//   }
//   @media screen and(max-width: 890px){
//     justify-content: flex-start;
//   }
// }
//
//
// .ajax-logos{
//   img{
//     max-width: 100px;
//   }
// }
// .footer-logos{
//   display:flex;
//   flex-flow: row wrap;
//   justify-content: space-around;
//   align-items: center;
//   max-width: 600px;
//   width: 100%;
//   margin: 0 auto;
//   padding-top: 15px;
//   .logo{
//     flex: 0 0 63px;
//     opacity: .3;
//     &.color{
//       flex: 0 0 103px;
//       opacity: 1;
//     }
//   }
// }
// @media screen and(max-width: 600px){
//   .footer-logos{
//     .logo{
//       margin: 0 20px;
//       &.color{
//         flex: 0 0 100%;
//         order: -1;
//         width: 100%;
//         text-align: center;
//         .heroPhotoElement{
//           width: 100px;
//           display: inline-block;
//           float: none;
//         }
//       }
//     }
//   }
// }

.secondary-footer{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
}

.footer-social{
  order: 1;
  .theme-social-media{text-align: left;}
  .theme-social-media .sn-social-media-icon{
    background-color: transparent;
    color: #757575;
    border: 2px solid #757575;
    width: 32px;
    height: 32px;
    &:hover{
      background-color: transparent !important;
      color: #fff;
      border: 2px solid #fff;
    }
    &:before{
      line-height: 29px;
    }
  }
}

.footer-content{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
}
.footer-links{
  margin: 0 20px;
  .textBlockElement{
    margin-top: 0;
    > h3{
      font-size: 14px;
      margin-bottom: 25px;
      #siteFooter & {color: #FFF;}
    }
    p{
      margin-bottom: 12px;
    }
    p a{
      font-size: 12px;
      text-transform: uppercase;
      color: #666;
      font-weight: 400;
      &:hover{color: #FFF;text-decoration: none;}
    }
  }
}
#siteFooter{
  @media screen and (max-width: 530px){
    .footer-content{
      width: 100%;
    }
    .footer-links{
      width: 100%;
      margin: 0;
    }
    .footer-links .textBlockElement > h3{ text-align: center !important;}
    .footer-links .textBlockElement .text,
    .footer-social .theme-social-media{ text-align: center;}
  }
}

.footer-logo{
  margin-right: auto;
  @media screen and (max-width: 670px){
    width: 100%;
  }
  span{
    margin: 0 auto 32px auto;
    display: block;
    background-image: url(../images/site_logo_square_volleyball.svg);
    #facility & { background-image: url(../images/site_logo_square_sports.svg); }
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    width: 96px;
    height: 90px;
  }
}
