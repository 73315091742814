body#survey_steps { padding: 0; }

html {

  &.has-mobile-nav {

    // position relative to avoid conflicts with the smart app banner (adds html margin)
  }

  &.page-manager-visible {
    body { padding-left: $page-manager-width; }
  }

  // &.nav-fixed {
  //   .site-background {
  //     margin-top: $nav-placeholder-height-fixed - $nav-placeholder-height;
  //     bottom: $nav-placeholder-height-fixed - $nav-placeholder-height;
  //   }
  // }

  // &:not(.collapsed-mobile-nav) {
  //
  //   .site-background {
  //     transition: margin-top $transition-linear, bottom $transition-linear;
  //   }
  //
  //   &:not(.has-sub-nav) {
  //     .site-background { margin-top: -$sub-nav-height; }
  //
  //     &.nav-fixed {
  //       .site-background {
  //         margin-top: $nav-placeholder-height-fixed - $nav-placeholder-height - $sub-nav-height-fixed;
  //       }
  //     }
  //   }
  // }
  //
  // &.collapsed-mobile-nav {
  //   .site-background { margin-top: -$nav-placeholder-height; }
  // }
  &.has-main-nav{
    .site-background{
      margin-top: $top-nav-height;
    }
  }
  &.page-manager-visible{
    .site-background{
      margin-left: $page-manager-width;
    }
  }
}

.site-background{
  // background-attachment: fixed;
  background-size: cover;
  &:before{
    content: '';
    display: block;
    width: 100%;
    // height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 330px;
    position: absolute;
    background-image: linear-gradient(to top, #CCC 0%, transparent 33%);
  }
}
