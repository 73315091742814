
#sn-site-logo {
  position: relative;
  transition: all $transition-linear;
  &:before{
    content: "";
    background-image: url(../images/site_tagline_default.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 107px;
    height: 13px;
    display: block;
    margin-bottom: 6px;
    .has-mobile-nav &{
      display: none;
    }
  }


  .sn-site-logo-wrap {
    display: table;
    table-layout: fixed;
    width: 474px;
    height: 78px;

    .has-mobile-nav &{
      width: 100%;
      max-width:230px;
      height:38px;
    }
  }

  .sn-site-logo-background {
    display: table-cell;
    vertical-align: middle;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    -webkit-background-size: contain;
  }
}
