// Utilities
$sn-pageManager-width: 60px;
%standard-width{
  max-width: $maxWidth;
  width: 100%;
  margin: 0 auto;
}

%full-width{
  width: 100vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  .page-manager-visible & {
    width: calc(100vw - #{$sn-pageManager-width});
    // margin-left: -30px;
  }
}

%full-width-image-background{
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.full-width{
  .user_mode &{@extend %full-width;}
  // @extend %full-width;
  // margin-right: 0;
  &.layout-100{
    padding-left: 0;
    .column{ padding-left: 0; padding-right: 0;}
  }
}
.full-width-container{
  .user_mode &{@extend %full-width;}
  // @extend %full-width;
  // @extend %full-width-image-background;
  // margin-right: 0;
  .fw-content{
    @extend %standard-width;
    .user_mode &{
      padding-left: $site-column-padding-h;
      padding-right: $site-column-padding-h;
    }
  }
}
.background-image{
  .user_mode &{ display: none; }
  .edit_mode &{
    &:before{
      max-width: 300px;
      margin: 0 auto;
      display: block;
      padding: 1em;
      text-align: center;
      content: "This image will be used as the background for this layout container. It will only appear here (as a page element) in edit-mode.";
    }
    img{
      max-width: 300px;
      margin: 0 auto;
      border: 2px solid #FFF;
      box-shadow: 0px 0px 15px rgba(0,0,0,.5);
    }
  }
}
.background-cover-img{
  @extend %full-width-image-background;
}


.compact{
  // &.layoutContainer{padding-left: 0;}
  // .column{padding-left:0;padding-right:0;}
  &.pageEl .pageElement,
  .pageEl .pageElement{
    margin-top: 0;
    margin-bottom: 0;
  }
}

.layoutContainer.padding{
  padding-top: $site-element-padding-v;
  padding-bottom: $site-element-padding-v;
  &-top{padding-top: $site-element-padding-v;}
  &-bottom{padding-bottom: $site-element-padding-v;}
}
.center-align{
  .fw-content, &.layoutContainer{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }
}


.blue{
  &.layoutContainer{background-color: $blue-dk;}
}
.gray{
  &.layoutContainer{background-color: $gray-dk;}
}
.white{
  &.layoutContainer{background-color: #FFF;}
}
.maintain-ratio .video_aspect_ratio {
    padding-bottom: 0;
}
.blue-text{
  .text{color:$blue-dk;}
}

.element-header{
  .textBlockElement > h3 {@include special-header;}
  .textBlockElement > h3:only-child {margin-bottom: 0;}
  &.center{
    .textBlockElement > h3:after{ margin: 0 auto; }
  }
   &.small .textBlockElement > h3:after{
    border-bottom: 1px solid $gray-md;
  }
  &.large .textBlockElement > h3:after{
    border-bottom: 10px solid $gray-lt;
  }
  &.compact .textBlockElement > h3:after{
    margin: 0;
  }
}

// .user_mode [href$=".com/assets"] {
//     display: none !important;
// }


//
// #home #siteContainer{
//   padding-top:0;
//   padding-bottom:0;
// }



.background-facility{
  background-image: url(../images/background-facilities.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

//custom sport tools tabs
#sport-header .sport-tools .selected a {
    color: white;
    text-shadow: none;
}
#sport-header .sport-tools ul li a {
    text-shadow: inherit;
    color: white;
    padding: 10px;
    border-right: 1px solid white;
}
#sport-header .sport-tools ul .selected {
    border-bottom: none;
    background: #155f8e;
}
#sport-header .sport-tools ul li:hover {
    border: none;
    background: #155f8e;
}
