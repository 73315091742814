/* Event Aggregators */
// 1. Global Styles
// 2. List Views
// 3. List Views - Media Queries
// 4. Five Day View
// 5. Five Day View - Media Queries

$event-entry-padding-vert: 15px;
$event-entry-padding-horz: 15px;
$event-entry-spacing: 1px;
$event-entry-spacing-top: 20px;
$event-date-box-width: 45px;
$event-date-box-width-padding: $event-date-box-width + ($event-entry-padding-horz * 2); // Dont know why 5px fixes this????

// 1. Global Styles
.eventAggregatorElement {
  .aggHeader {
    @include special-header;
  }
  .vevent + .extendedOptions{
    // background-color: $blue-lt;
    // color: #FFF;
    padding: 10px $event-entry-padding-horz;
    a{
      // color: #FFF;
    }
  }
}

// 2. List Views
.eventAggregatorElement {
  .aggHeader + .vevent{
    margin-top: $event-entry-spacing-top;
  }
  .vevent {
    position: relative;
    padding: $event-entry-padding-vert $event-entry-padding-horz;
    margin-bottom: $event-entry-spacing;
    background-color: $blue-lt;
    transition: background-color $transition-standard;

    color: #FFF;
    @include font-smoothing;


    display: flex;
    flex-flow: column;
    min-height: 103px;
    align-items: flex-start;
    justify-content: center;
    &:hover{
      background-color: #60A6D3;
    }
    a{
      color: $blue-dk;
      font-weight: 400;
      &:hover{
        color: #FFF;
        text-decoration: none;
      }
    }
    &:before{
      display: block;
      content: "";
      width: 1px;
      background-color: #FFF;
      position: absolute;
      top: 0;
      bottom: 0;
      left: $event-date-box-width-padding;
      margin: 15px 0;
    }

    &.odd,
    &.even {
      // background-color: transparent;
    }
  }
  .summary,
  .details {
    list-style: none;
    padding-left: $event-date-box-width-padding;
    line-height: 1.2;
  }
  .summary {
    font-family: $fontThree;
    font-weight: 400;
    font-size: em(17);
    text-transform: none;
    color: $blue-dk;
    margin-bottom: 5px;
  }
  .details{
    // padding-bottom: $event-entry-padding-vert;
    color: $blue-dk;
  }
  .time,
  .location {
    display: inline-block;
    margin-right: 5px;
    font-size: em(17);
    font-family: $fontThree;
  }
  .time{
    & [title="Download"]{
      margin-left: 5px;
    }
  }
  .location { font-weight: 400; }

  .tags { font-size: 12px; display: none;}
  .description {
    &:empty{display: none;}
    margin-top: em(12);
  }
  .dateImage {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding: 8px $event-entry-padding-horz 8px 0;
    width: $event-date-box-width;

    box-sizing: content-box;
    list-style: none;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    font-family: $fontThree;
    color: #FFF;
    .month, .date{
      width: 100%;
      font-weight: 400;
    }
    .month {
      font-size: em(18);
      letter-spacing: .5px;
      padding: 3px 0;
    }
    .date {
      font-size: em(33);
    }
  }
  .formElement {
    padding: 10px 0; // This should be better defined in global form styles (or removed from there to be styled per element)
    [id$="_start_date_day"],
    [id$="_end_date_day"] {
      width: 8em;
    }
  }
}

// 3. List Views - Media Queries
@include page-element-break(330) {
  .eventAggregatorElement {
    .vevent:before{display: none;}
    .dateImage {
      position: relative;
      top: auto;
      transform: none;
      padding-bottom: $event-entry-padding-vert;
      padding-top: 0;
      flex-direction: row;
      justify-content: flex-start;
      width: auto;
      .month, .date{
        font-size: em(20);
        padding-right: 5px;
        font-weight: 400;
        width: auto;
      }
    }
    .summary,
    .details {
      padding-left: 0;
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 999px) {
  .layout-25-25-50,
  .layout-50-25-25 {
    .col-md-6,
    .col-md-3 {
      .eventAggregatorElement {
        .vevent:before{display: block;}
        .dateImage {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          flex-direction: row;
          justify-content: flex-start;
          width: auto;
          .month, .date{
            font-size: em(20);
            padding-right: 5px;
            font-weight: 400;
            width: auto;
          }
        }
        .summary,
        .details {
          padding-left: $event-date-box-width-padding;
        }
      }
    }
  }
  .layout-25-50-25 {
    .col-md-6,
    .col-md-3 {
      .eventAggregatorElement {
        .vevent:before{display: none;}
        .dateImage {
          position: relative;
          top: auto;
          transform: none;
          flex-direction: row;
          justify-content: flex-start;
          width: auto;
          .month, .date{
            font-size: em(20);
            padding-right: 5px;
            font-weight: 400;
            width: auto;
          }
        }
        .summary,
        .details {
          padding-left: 0;
        }
      }
    }
  }
}

// 4. Five Day View
.eventPreview {
  .aggHeader + &{ margin-top: $event-entry-spacing-top; }
  text-align: left;
  width: 100%;
  table-layout: fixed;
  th {
    @include table-th;
    @include table-th-text;
    word-break: break-word;
    a {
      color:#FFF;
      font-weight: 600;
    }
  }
  td {
    font-size: 12px;
    padding: 10px;
    background: #eee;
    vertical-align: top;
    word-break: break-word;
    &:before {
      content: attr(data-week-view-date)'';
      display: none;
      width: 100%;
      position: relative;
      box-sizing: border-box;
      padding: 7px 12px;
      border-bottom: $table-cell-spacing solid #FFF;
      background: $link_color;
      color: #fff;
      text-transform: uppercase;
      font-family: $fontOne;
      font-size: 1em;
      line-height: 1.1;
      font-weight: 600;
    }
  }
  td,
  th {
    border: $table-cell-spacing solid #FFF;
    &:first-child {
      border-left: 0;
    }
    &:last-child {
      border-right: 0;
    }
  }
  .event + .event {
    padding-top: 10px;
  }
  .noentry {
    word-break:normal;
  }
}

// 5. Five Day View - Media Queries
@include page-element-break(430) {
  .eventPreview {
    border-top: $table-cell-spacing solid #FFF;
    &,
    tbody,
    tr,
    td {
      display: block;
    }
    thead {
      display: none;
    }
    td {
      border: 0;
      padding: 0;
      margin-bottom: $table-cell-spacing;
      word-break: word-break;
      &:before {
        display: block;
      }
    }
    .event {
      padding: 10px;
      & + .event {
        padding-top: 0;
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 999px) {
  .layout-25-25-50,
  .layout-50-25-25 {
    .col-md-6 {
      .eventPreview {
        display: table;
        border-top: 0;
        thead {
          display: table-header-group;
        }
        tbody {
          display: table-row-group;
        }
        tr {
          display: table-row;
        }
        td,
        th {
          display: table-cell;
        }
        td {
          padding: 10px;
          margin-bottom: none;
          word-break: word-break;
          &:before {
            display: none;
          }
        }
        .event {
          padding: 0;
        }
      }
    }
  }
  .layout-25-50-25 {
    .col-md-6 {
      .eventPreview {
        border-top: $table-cell-spacing solid #FFF;
        &,
        tbody,
        tr,
        td {
          display: block;
        }
        thead {
          display: none;
        }
        td {
          border: 0;
          padding: 0;
          margin-bottom: $table-cell-spacing;
          word-break: word-break;
          &:before {
            display: block;
          }
        }
        .event {
          padding: 10px;
          & + .event {
            padding-top: 0;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 891px) and (max-width: 999px) {
  .layout-25-25-50,
  .layout-50-25-25 {
    .col-md-3 {
      .eventPreview {
        display: table;
        border-top: 0;
        thead {
          display: table-header-group;
        }
        tbody {
          display: table-row-group;
        }
        tr {
          display: table-row;
        }
        td,
        th {
          display: table-cell;
        }
        td {
          padding: 10px;
          margin-bottom: none;
          word-break: word-break;
          border: $table-cell-spacing solid #FFF;
          &:first-child {
            border-left: 0;
          }
          &:last-child {
            border-right: 0;
          }
          &:before {
            display: none;
          }
        }
        .event {
          padding: 0;
        }
      }
    }
  }
}
