/* Content Tabbed Navigation
-------------------------------------------------------------------- */

.contentTabs {
  list-style-type: none;
  text-align: left;
  width: 100%;

  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;

  li {
    @include content-tab-item;
    margin-bottom: 1px;
    // + li{margin-left: 5px;}
    // &:first-of-type{margin-left: 0;}
    // &:last-of-type{margin-right: 0;}
    width: 50%;

    span {
      background-repeat: no-repeat;
      background-position: bottom left;
      text-decoration: none;
      display: block;
      a{
        width: 100%;
        display: block;
        text-align: center;
      }
    }
    a {
      @include content-tab;
      color: #FFF;
      span { display: inline; }
    }


    &:hover,
    &.selected {
      background-color:  $blue-dk;
      a{
         color: #FFF;
      }
    }
  }

  &.rapidTabsConstruction a.tabLink {
    padding-top: 4px;
    padding-left: 8px;
    display: inline-block;
    background: none;
  }
}

.pageElementAdmin .rosterPlayerStatElement .contentTabs { margin: 0; }

.dataTable .disabled {
  color: #000;
  text-decoration: none;

  &:hover { text-decoration: underline; }
}


/* Loading Tab Animation
-------------------------------------------------------------------- */
#contentTabsLoader {
  text-align: center;
  clear: both;
  padding: 25px;
}


/* Rapid Tabs Construction
-------------------------------------------------------------------- */
.rapidTabsConstruction {
  background: #FDDD40;
  margin-bottom: 0;
  border-top: solid 1px #DDA301;
  padding: 0 0 1px;
  justify-content: center;

  li {
    border-right: solid 1px #DDA301;
    border-bottom: solid 1px #DDA301;
    padding-right: 0;
    background: #FDDD40;
    flex: 1 1 auto;
    margin: 0;
    + li{margin: 0;}
    &:hover, &.selected{
      background: #FDE872;
    }
    a {
      font-size: 14px;
      font-family: $fontTwo;
    }

    span {
      background: none;
      padding-left: 0;

      a {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }

}




@include page-element-break(400) {
  .tabbedElement {
    .contentTabs{
      li {
        width: auto;
        span a {
          width: auto;
          display: inline-block;
        }
      }
    }
  }
}
// 
//
// @media only screen and (max-width: 1024px) {
//
//   .tabbedElement {
//     .contentTabs {
//       li {
//         width: 33.333%;
//
//         span {
//           a {
//             width: 100%;
//             display: block;
//             text-align: center;
//           }
//         }
//       }
//     }
//   }
// }
//
// @media only screen and (max-width: 767px) {
//   .tabbedElement {
//     .contentTabs{
//       li {
//         width: 50%;
//         span a {
//           width: 100%;
//           display: block;
//           text-align: center;
//         }
//       }
//     }
//   }
// }
